export const ACTIVITY_STATUS = {
  OPEN: 'OPEN',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
};

export const activities = {
  values: {
    type: 'values',
    level: 1,
    results: ['values', 'culture', 'organisation'],
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: null,
  },
  strength_assessment: {
    type: 'strength_assessment',
    level: 1,
    results: ['strengths'],
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: null,
  },
  interests: {
    type: 'interests',
    level: 1,
    results: ['interests'],
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: null,
  },
  vision: {
    type: 'vision',
    level: 1,
    results: ['vision'],
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: null,
  },
  personality_test: {
    type: 'personality_test',
    level: 2,
    results: ['personality', 'competencies', 'roles'],
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: null,
  },
  experience: {
    type: 'experience',
    level: 2,
    results: ['experiences'],
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: 'personality_test',
    lockedText: "You must complete 'Personality Test' first",
  },
  cognative_ability_test: {
    type: 'cognative_ability_test',
    level: 2,
    results: ['abilities'],
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: null,
  },
  goals: {
    type: 'goals',
    level: 3,
    results: ['goals'],
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: null,
  },
  actions: {
    type: 'actions',
    level: 3,
    results: ['actions'],
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: 'goals',
    lockedText: "You must complete 'Your SMART Goals' first",
  },
  limiting_beliefs: {
    type: 'limiting_beliefs',
    level: 3,
    results: ['beliefs'],
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: null,
  },
  ongoing_vision: {
    type: 'vision',
    cmsType: 'ongoing_vision',
    level: 4,
    ongoing: true,
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: null,
  },
  ongoing_goals: {
    type: 'goals',
    cmsType: 'ongoing_goals',
    level: 4,
    ongoing: true,
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: null,
  },
  ongoing_actions: {
    type: 'actions',
    cmsType: 'ongoing_actions',
    level: 4,
    ongoing: true,
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: null,
  },
  ongoing_limiting_beliefs: {
    type: 'limiting_beliefs',
    cmsType: 'ongoing_limiting_beliefs',
    level: 4,
    ongoing: true,
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: null,
  },
  interview_guide: {
    type: 'interview_guide',
    level: 4,
    results: ['interview_guide'],
    paid: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: null,
  },
  cv_guide: {
    type: 'cv_guide',
    level: 4,
    ongoing: true,
    marketplace: true,
    goToActivitiesAfterComplete: true,
    dependentActivity: null,
  },
};

const levels = {
  1: {
    totalActivities: 4,
    activities: [
      activities.vision,
      activities.values,
      activities.interests,
      activities.strength_assessment,
    ],
  },
  2: {
    totalActivities: 3,
    activities: [
      activities.personality_test,
      activities.experience,
      activities.cognative_ability_test,
    ],
  },
  3: {
    totalActivities: 3,
    activities: [
      activities.goals,
      activities.actions,
      activities.limiting_beliefs,
    ],
  },
  4: {
    ongoing: true,
    activities: [
      activities.ongoing_vision,
      activities.ongoing_goals,
      activities.ongoing_actions,
      activities.ongoing_limiting_beliefs,
    ],
  },
};

export const TOTAL_ACTIVITIES =
  levels[1].totalActivities +
  levels[2].totalActivities +
  levels[3].totalActivities;

export default levels;
